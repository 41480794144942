.react-tagsinput {

    overflow: hidden;
}



.react-tagsinput-tag {
    background-color: #cde69c;
    border-radius: 2px;
    border: 1px solid #a5d24a;
    color: #638421;
    display: inline-block;
    font-family: sans-serif;
    font-size: 13px;
    padding: 2px 7px;
    font-weight: 400;
    margin-right: 5px;
}

.react-tagsinput-remove {
    cursor: pointer;
    font-weight: bold;
}

.react-tagsinput-tag a::before {
    content: " ×";
}

.react-tagsinput-input {
    background: transparent;
    outline: none;
}